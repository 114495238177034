<template>
  <v-overlay :value="showDialog" class="dialog dialog-disconnect">
    <v-card light class="card-rounded not-a-link" width="725px" v-click-outside="closeOuterDialogHandler">
      <v-toolbar height="71">
        <v-container class="px-0 d-flex justify-space-between">
          <v-card-title class="h3 pa-0">
            {{ title }}
          </v-card-title>
          <v-icon size="30px" color="#ffffff" class="mt-1" @click="closeOuterDialogHandler">close</v-icon>
        </v-container>
      </v-toolbar>

      <div class="d-flex justify-space-between dialog-body pa-10">
        <Card
          actionText="Disconnect &amp; Keep"
          bodyText="Unsync without deleting the product from your destination store."
          btnClass="btn-primary"
          eventName="disconnect-and-keep-confirmation"
          icon="link_off"
          title="Disconnect &amp; Keep"
          v-on="$listeners">
        </Card>

        <Card
          actionText="Disconnect &amp; Delete"
          bodyText="Unsync and delete the product from your destination store."
          btnClass="btn-danger"
          eventName="show-delete-confimation-dialog"
          icon="delete_forever"
          title="Disconnect &amp; Delete"
          v-on="$listeners">
        </Card>
      </div>

      <v-overlay :value="showInnerDialog" class="dialog-inner">
        <v-card light class="card-rounded not-a-link" width="460px" v-click-outside="closeInnerDialogHandler">
          <div class="dialog-body px-9 py-7">
            <h3 class="h3 d-flex mt-2">
              <v-icon class="mr-5" size="22px" color="#D91E18">error</v-icon>
              {{ deleteDialogTitle }}
            </h3>

            <p class="body-text mt-3 mb-0" v-html="deleteMessage"></p>

            <v-checkbox v-model="isDeleteEnabled" label="I understand this action cannot be undone."></v-checkbox>
            <aside class="d-flex justify-end align-center pt-6">
              <v-btn class="btn-danger mr-6" :class='{"btn-disabled": !isDeleteEnabled }' height="48px" elevation="0" :disabled="!isDeleteEnabled" @click="deleteHandler">
                Delete all products
              </v-btn>
              <span class="btn-dismiss" @click="closeInnerDialogHandler">Cancel</span>
            </aside>
          </div>

        </v-card>
      </v-overlay>

      <v-overlay :value="showInnerDialogForKeep" class="dialog-inner">
        <v-card light class="card-rounded not-a-link" width="460px" v-click-outside="closeInnerDialogKeepHandler">
          <div class="dialog-body px-9 py-7">
            <h3 class="h3 d-flex mt-2">
              <v-icon class="mr-5" size="22px" color="#D91E18">error</v-icon>
              {{ deleteDialogKeepTitle }}
            </h3>

            <p class="body-text mt-3 mb-0" v-html="deleteAndKeepMessage"></p>

            <aside class="d-flex justify-end align-center pt-6">
              <v-btn class="btn-primary mr-6" height="48px" elevation="0" @click="disconnectAndKeepHandler">
                Disconnect &amp; Keep
              </v-btn>
              <span class="btn-dismiss" @click="closeInnerDialogKeepHandler">Cancel</span>
            </aside>
          </div>

        </v-card>
      </v-overlay>

    </v-card>
  </v-overlay>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'confirmation-box',

  data() {
    return {
      isDeleteEnabled: false
    }
  },

  props: {
    title: {
      type: String,
      required: true
    },
    showDialog: {
      type: Boolean,
      required: true
    },
    showInnerDialog: {
      type: Boolean,
      required: true
    },
    showInnerDialogForKeep: {
      type: Boolean,
      required: true
    },
    deleteDialogTitle: {
      type: String,
      required: false,
    },
    deleteDialogKeepTitle: {
      type: String,
      required: true
    },
    deleteMessage: {
      type: String,
      required: false
    },
    deleteAndKeepMessage: {
      type: String,
      required: true
    }
  },

  components: {
    Card
  },

  methods: {
    deleteHandler() {
      this.$emit('on-delete-handler');
      this.isDeleteEnabled = false;
    },

    disconnectAndKeepHandler() {
      this.$emit('disconnect-and-keep');
    },

    closeOuterDialogHandler() {
      this.$emit('close-outer-dialog-handler', false);
    },

    closeInnerDialogHandler() {
      this.$emit('close-inner-dialog-handler', false);
      this.isDeleteEnabled = false;
    },

    closeInnerDialogKeepHandler() {
      this.$emit('close-inner-dialog-keep-handler', false);
    }
  }
}
</script>
