<template>
  <v-card class="card-rounded not-a-link px-4 pt-10 py-5 text-center" light elevation="0" width="305px">
    <v-icon size="46px" color="#0E3B4D" class="mt-3">{{ icon }}</v-icon>
    <h2 class="mt-6">{{ title }}</h2>
    <p class="body-caption mt-3 mb-0 px-2">{{ bodyText }}</p>
    <v-btn class="mt-6" height="48px" elevation="0" :class="btnClass" block @click="emitEventHandler">{{ actionText }}</v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'card',

  props: {
    title: {
      type: String,
      required: false
    },

    bodyText: {
      type: String,
      required: false
    },

    actionText: {
      type: String,
      required: false
    },

    icon: {
      type: String,
      requird: false
    },

    eventName: {
      type: String,
      required: true
    },

    btnClass: {
      type: String,
      required: false
    }
  },

  methods: {
    emitEventHandler() {
      this.$emit(this.eventName)
    }
  }
}
</script>
