<template>
  <div class="inventory-assignment-wrapper">
    <div class="py-2">
      <v-select :items="destinationLocations" @change="showConfirmation($event)" dense
        :error="!item.destination_default_inventory_location && !isMultiLocationLoading" outlined
        :placeholder="locationPlaceholder" hide-details="" :disabled="isMultiLocationLoading" item-text="name"
        item-value="id" :loading="isMultiLocationLoading" no-data-text="No location availale"
        v-model="selectedInventoryLocationId" v-if="(item && item.platform === 'shopify')">
      </v-select>

      <template v-else>
        <span v-if="isMultiLocationLoading">
          Fetching location
        </span>
        <span v-else>
          {{ destinationLocations[0] && destinationLocations[0].name }}
        </span>
      </template>

      <v-overlay :value="openDialog">
        <v-card class="card-rounded not-a-link inventory-change-dialog pa-11 text-center" light width="578px">
          <h3 class="h3 mb-5">
            Confirm inventory location change
          </h3>

          <p class="mt-7 mb-5">
            You're about to change the inventory receiving location for <br> <strong>{{ item.store_domain }}</strong>
          </p>

          <p>
            <Strong>From:</Strong> {{ item.destination_default_inventory_location &&
              item.destination_default_inventory_location.name }}
          </p>

          <p>
            <Strong>To:</Strong> {{ selectedInventoryLocationName }}
          </p>

          <div class="d-flex justify-center align-center mt-8">
            <v-btn @click="changeLocationHandler" class="btn-primary mr-6" elevation="0" :loading="isProcessing">
              Confirm
            </v-btn>
            <span @click="resetLocation()" class="btn-dismiss pointer">Cancel</span>
          </div>
        </v-card>
      </v-overlay>

      <v-overlay :value="isSuccessDialogVisible">
        <v-card class="card-rounded not-a-link inventory-change-dialog success-dialog" light>
          <div class="inventory-change-header text-center py-5">
            <v-icon color="#fff" size="41px">check_circle</v-icon>
          </div>

          <div class="inner">
            <h3 class="h3 mb-3 text-center">
              Location Changed
            </h3>

            <p class="mb-6">
              <i>{{ item.store_domain }}</i>'s products will be resynced to reflect the new location's inventory quantity.
            </p>

            <p>This may take up to 24 hours. <br> An e-mail will be sent to you once the resync is completed.</p>

            <div class="d-flex justify-end align-center">
              <v-btn @click="closeLocationChangeSuccessHandler" class="btn-primary ml-6" elevation="0">OK</v-btn>
            </div>
          </div>
        </v-card>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'DestinationInventoryAssignment',

  props: ["item", "isMultiLocationLoading"],

  data() {
    return {
      isLoading: false,
      isProcessing: false,
      isSuccessDialogVisible: false,
      openDialog: false,
      selectedInventoryLocationId: 0,
      selectedInventoryLocationName: ''
    };
  },

  computed: {
    ...mapState("shop", ["currentShop"]),
    ...mapGetters({
      destinationLocations: "shop_2_0/GET_DESTINATION_STORE_LOCATIONS"
    }),

    locationPlaceholder() {
      if (!this.item.destination_default_inventory_location && !this.isMultiLocationLoading) {
        return 'Select a location';
      } else {
        return 'Fetching location';
      }
    },

    sourceStoreLocationId() {
      const { source_default_inventory_location } = this.item;
      return source_default_inventory_location ? +source_default_inventory_location.external_reference_id : null;
    }
  },

  mounted() {
    this.setLocation();
  },

  watch: {
    'item.destination_default_inventory_location'() {
      this.setLocation();
    }
  },

  methods: {
    setLocation() {
      if (this.item.destination_default_inventory_location !== null) {
        this.selectedInventoryLocationId = +this.item.destination_default_inventory_location.external_reference_id;
      } else {
        this.selectedInventoryLocationId = 0;
      }
    },

    resetLocation() {
      this.setLocation();
      this.openDialog = false;
    },

    showConfirmation($event) {
      const selectedLocation = this.destinationLocations.filter(location => {
        return +location.id === +$event;
      });
      this.selectedInventoryLocationName = selectedLocation[0].name;
      this.openDialog = true;
    },

    async changeLocationHandler() {
      try {
        this.isProcessing = true;
        const response = await this.$store.dispatch('shop_2_0/updateDestinationStoreLocation', {
          d_inventory_reference: this.selectedInventoryLocationId,
          destination_store_id: this.currentShop.id,
          is_default: true,
          name: this.selectedInventoryLocationName,
          s_inventory_reference: this.sourceStoreLocationId,
          source_store_id: this.item.id,
          store_type: this.currentShop.type,
          sync_option: 'keep',
        });

        if (response.data.success) {
          this.isProcessing = this.openDialog = false;
          this.isSuccessDialogVisible = true;
        }
      } catch {
        this.setLocation();
        this.isProcessing = this.openDialog = false;
      }
    },

    async closeLocationChangeSuccessHandler() {
      await this.$store.dispatch(`shop/init`);
      this.isSuccessDialogVisible = false;
    }
  }
}
</script>

<style lang="scss">
.location-selector {
  appearance: auto;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 12px;
  padding: 5px 10px;
}
</style>
