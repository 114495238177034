<template>
<div class="inventory-assignment-wrapper">
  <data-preparation-overlay v-if="isLoading"></data-preparation-overlay>
  <div v-else class="py-2">
    <v-select
      :items="localAvailableInventories"
      @change="showConfirmation"
      dense
      outlined
      hide-details=""
      item-text="name"
      item-value="id"
      no-data-text="No location available"
      v-if="(currentShop.type != 'destination' && (currentConnectedShop && currentConnectedShop.platform === 'shopify'))"
      :placeholder="locationPlaceHolder"
      v-model="selectedInventoryLocationId">
      <template v-slot:selection="{ item }">
        {{ getText(item) }}
      </template>
      <template v-slot:item="{ item }">
        {{ getText(item) }}
      </template>
    </v-select>

    <template v-else>
      <span class="pl-3">
        All locations
      </span>
    </template>

    <v-overlay :value="openDialog">
      <v-card class="card-rounded not-a-link inventory-change-dialog" light>
        <h3 class="h3 mb-5">
          <v-icon class="mr-2" size="24px" color="#FCB058">location_on</v-icon>
          Change Locations?
        </h3>

        <p class="mb-6">
          You are about to change the inventory location of <i>{{ currentConnectedShop.store_domain }}</i> from <b><span>{{ oldLocation }}</span> to <span>{{ newLocation }}</span></b>.
        </p>

        <div class="d-flex justify-end align-center">
          <span class="btn-dismiss pointer" @click="resetLocation">Cancel</span>
          <v-btn @click="changeLocation" class="btn-primary ml-10" elevation="0">
            <template v-if="isProcessing">Processing...</template>
            <template v-else>Change Location</template>
          </v-btn>
        </div>
      </v-card>
    </v-overlay>

    <v-overlay :value="isSuccessDialogVisible">
      <v-card class="card-rounded not-a-link inventory-change-dialog success-dialog" light>
        <div class="inventory-change-header text-center py-5">
          <v-icon color="#fff" size="41px">check_circle</v-icon>
        </div>

        <div class="inner">
          <h3 class="h3 mb-3 text-center">
            Location Changed
          </h3>

          <p class="mb-6">
            <i>{{ currentConnectedShop.store_domain }}</i>'s products will be resynced to reflect the new location’s inventory quantity. This may take up to 24 hours. An e-mail will be sent to you once the resync is completed.
          </p>

          <div class="d-flex justify-end align-center">
            <v-btn @click="isSuccessDialogVisible = false" class="btn-primary ml-6" elevation="0">OK</v-btn>
          </div>
        </div>
      </v-card>
    </v-overlay>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import ShopApiService from "@/common/api/shop.service";
import DataPreparationOverlay from '@/views/components/DataPreparationOverlay.vue';

export default {
  name: 'SourceInventoryAssignment',

  props: ["currentConnectedShop"],

  data() {
    return {
      isLoading: false,
      isProcessing: false,
      isSuccessDialogVisible: false,
      newLocation: '',
      oldLocation: '',
      openDialog: false,
      selectedInventoryLocationId: 0,
      localAvailableInventories: []
    };
  },

  components: {
    DataPreparationOverlay,
  },

  created: function() {
    this.localAvailableInventories = [...this.availableInventories]
    this.localAvailableInventories.push({
      id: 0,
      name: 'All Locations'
    })

    this.localAvailableInventories.sort((a, b) => {
      return a.id - b.id;
    });
    this.resetLocation();
  },

  watch: {
    selectedLocation(newVal, oldVal) {
      this.oldLocation = oldVal && oldVal.name;
    },
  },

  computed: {
    ...mapState("shop", ["currentShop", "availableInventories"]),
    currentInventoryLocation() {
      let location = null;
      if (this.currentShop !== null) {
        this.localAvailableInventories.forEach((inventory) => {
          if (this.currentConnectedShop?.source_default_inventory_location?.external_reference_id == inventory.id) {
            location = inventory;
          }
        });
      }
      return location;
    },

    selectedLocation() {
      let location = null;
      this.localAvailableInventories.forEach(inventory => {
        if (this.selectedInventoryLocationId == inventory.id && !this.openDialog) {
          location = inventory;
        }
      });
      return location;
    },

    locationPlaceHolder() {
      if(!this.currentConnectedShop.source_default_inventory_location && !this.isMultiLocationLoading) {
        return 'Select a location';
      }
      return 'Select a location';
    },

    destinationStoreLocationId() {
      const { destination_default_inventory_location } = this.currentConnectedShop;
      return destination_default_inventory_location ? +destination_default_inventory_location.external_reference_id : null;
    },
  },

  methods: {
    getText(item) {
      this.newLocation = item.name;
      return `${item.name}`;
    },

    showConfirmation() {
      this.openDialog = true;
    },

    resetLocation() {
      if (this.currentInventoryLocation != null) {
        this.selectedInventoryLocationId = this.currentInventoryLocation.id;
      } else if(this.currentConnectedShop.status !== 'pending') {
        this.selectedInventoryLocationId = 0;
      } else {
        this.selectedInventoryLocationId = null;
      }
      this.openDialog = false;
    },

    changeLocation() {
      this.isProcessing = true;
      if (this.currentShop.type != 'source') { return; }
      ShopApiService.setInventoryLocationToShop({
        source_store_id: this.currentShop.id,
        destination_store_id: this.currentConnectedShop.id,
        s_inventory_reference: this.selectedInventoryLocationId,
        d_inventory_reference: this.destinationStoreLocationId,
        is_default: true,
        name: this.newLocation,
        sync_option: "keep",
        store_type: this.currentShop.type
      }).then(() => {
        this.isSuccessDialogVisible = true;
      }).finally(() => {
        this.openDialog = this.isProcessing = false;
      });
    }
  }
}
</script>

<style lang="scss">
.location-selector {
  appearance: auto;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 12px;
  padding: 5px 10px;
}
</style>
