<template>
  <v-container class="shop-management-page pt-0">
    <DisconnectConfirmation
      :deleteMessage="deleteMessage"
      :deleteAndKeepMessage="deleteAndKeepMessage"
      :showDialog="showDisconnectConfirmation"
      :showInnerDialog="isDeleteDialogVisible"
      :showInnerDialogForKeep="isKeepDialogVisible"
      @close-inner-dialog-handler="onCloseInnerDialogHandler"
      @close-inner-dialog-keep-handler="onCloseInnerDialogKeepHandler"
      @close-outer-dialog-handler="onCloseOuterDialogHandler"
      @disconnect-and-keep="disconnectShop(true)"
      @disconnect-and-keep-confirmation="showKeepConfirmationDialog"
      @on-delete-handler="disconnectShop(false)"
      @show-delete-confimation-dialog="showDeleteConfirmationDialog"
      deleteDialogTitle="Disconnect &amp; Delete?"
      deleteDialogKeepTitle="Disconnect &amp; Keep?"
      title="Select a disconnect option:">
    </DisconnectConfirmation>

    <CommissionModal />

    <div v-if="currentShop === null || isLoadingConnectedShops">
      <data-preparation-overlay></data-preparation-overlay>
    </div>

    <div v-else>

      <v-row class="align-center">
        <v-col cols="4">
          <PageHeader :pageDescription="`Manage your ` + partnerStoreLabel">
            <template #title>
              Stores
            </template>
          </PageHeader>
        </v-col>
        <v-col cols="8">
          <v-row class="justify-end w-100">
            <v-col cols="6" class="justify-end">
              <div class="toggle-text-wrapper d-flex align-center" v-if="currentShop.platform === 'shopify' && currentShop.type === 'destination'" style="border: 1px solid rgba(0, 0, 0, 0.12)">
                <div class="toggle-text d-flex align-center">
                  <div style="line-height: normal;">
                    <div>Multi-Location Inventory</div>
                    <a
                    class="link link-underline bottom-0"
                    href="https://help.syncio.co/en/articles/4662246-how-does-syncio-work-when-i-have-multiple-inventory-locations"
                    target="_blank">
                      Learn more
                    <IconNewTab />
                  </a>
                  </div>
                  <v-chip
                    style="transform: scale(.75) translateY(-11px);"
                    color="#1E88E5"
                    href="http://help.syncio.co/en/articles/5842693-multi-locations-for-destination-stores"
                    link
                    pill
                    small
                    target="_blank"
                    text-color="#fff">
                      BETA
                    </v-chip>
                </div>
                <div style="transform: translateX(15px);">
                  <v-switch
                    :disabled="isMultiLocationLoading"
                    :loading="isMultiLocationLoading"
                    @click="updateMultiLocationModalHandler"
                    class="pt-0 mt-0"
                    color="blue darken-1"
                    dense
                    flat
                    hide-details
                    inset
                    v-if="isMultiLocationEnabled"
                    v-model="isMultiLocationEnabled">
                  </v-switch>
                  <v-switch
                    :disabled="isMultiLocationLoading"
                    :loading="isMultiLocationLoading"
                    @change="updateMultiLocationHandler"
                    class="pt-0 mt-0"
                    color="blue"
                    dense
                    hide-details
                    inset
                    v-else
                    v-model="isMultiLocationEnabled">
                  </v-switch>
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="justify-end">
              <connect-new-shop-widget type="button" label="Connect New Store"></connect-new-shop-widget>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="card-rounded not-from-top">
        <v-row class="pt-1 px-3 py-3">
          <v-col cols="10">
            <v-text-field
              v-if="currentShop != null && currentShop.type === 'destination'"
              append-icon="search"
              clearable
              dense
              label="Search Source Store by URL"
              outlined
              v-model="searchStr"
              v-on:keyup.enter="searchShops()"
              hide-details="" />
            <v-text-field
              v-else
              append-icon="search"
              clearable
              dense
              label="Search Destination Store by URL"
              outlined
              v-model="searchStr"
              v-on:keyup.enter="searchShops()"
              hide-details="" />
          </v-col>
          <v-col cols="2">
            <v-btn outlined height="40" @click="showSortByOptions = !showSortByOptions;" block class="sort-by-btn">
              <span class="mr-3">Sort</span>
              <v-icon>sort</v-icon>
            </v-btn>
            <v-card v-if="showSortByOptions" class="sortby-options">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Alphabetical Order</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-radio-group v-model="sortValue"  column @change="searchShops()">
                      <v-radio
                        :input-value="active"
                        label="A to Z"
                        value="atoZ"
                      ></v-radio>
                      <v-radio
                        :input-value="active"
                        label="Z to A"
                        value="ztoA"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0">
            <v-data-table
              :headers="currentShop.type === 'source' ? sourceHeaders : destinationTableHeader"
              :items="connectedShops"
              :loading="isLoading || currentShop === null"
              :items-per-page="-1"
              :hide-default-footer="true"
              disable-pagination
              class="elevation-1 store-connections-table"
            >
              <template v-slot:no-data>
                <div class="mt-1">
                  <v-card-text>
                    You are currently not connected to any
                    {{ partnerStoreLabel }}
                  </v-card-text>
                  <connect-new-shop-widget
                    type="link"
                    :label="'Connect a ' + partnerStoreLabel"
                  ></connect-new-shop-widget>
                </div>
              </template>
              <template v-slot:item.platform="{ item }">
                <div v-if="item.platform === 'woocommerce'">
                  <v-img
                    max-height="25"
                    max-width="25"
                    src="../../../assets/images/woo_logo.png"
                    class="text-center"
                  ></v-img>
                </div>
                <div v-else-if="item.platform === 'shopify'">
                  <v-img
                    max-height="25"
                    max-width="25"
                    src="../../../assets/images/shopify_logo.png"
                  ></v-img>
                </div>
                <div v-else>
                  {{ item.platform }}
                </div>
              </template>
              <template v-slot:item.status="{ item }" v-if="currentShop.type === 'destination'">
                <v-chip :class="{'active-status-chip': getStatus(item) === 'active', 'pending-status-chip' : getStatus(item) === 'pending' || getStatus(item) === 'pending-with-no-source' }">
                  <v-icon class="status-circle">stop_circle</v-icon>
                  <span class="text-capitalize d-flex">
                    <template v-if="getStatus(item) === 'active'">
                      Active
                    </template>
                    <template v-if="getStatus(item) === 'pending' || getStatus(item) === 'pending-with-no-source'">
                      Pending
                    </template>
                  </span>
                </v-chip>
                <v-tooltip bottom v-if="getStatus(item) === 'pending-with-no-source'">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="pending-with-no-source-icon absolute" small>info</v-icon>
                  </template>
                  Source store has no location set
                </v-tooltip>
              </template>
              <template v-slot:item.label="{ item }">
                <div>{{ item.store_domain }}</div>
              </template>
              <template v-slot:item.location="{ item }" v-if="currentShop.type !== 'destination' && currentShop.platform === 'shopify'">
                <source-inventory-assignment :current-connected-shop="item" />
              </template>
              <template v-slot:item.location="{ item }" v-else-if="currentShop.type === 'destination' && isMultiLocationEnabled && currentShop.platform === 'shopify'">
                <DestinationInventoryAssignment
                  :item="item"
                  :isMultiLocationLoading="isMultiLocationLoading" />
              </template>
              <template v-slot:item.actions="{ item }">
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        @click="openProductPageWithShop(item)"
                        :icon="['fas', 'boxes']"
                        class="mr-3 action-icon"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>View products</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        @click="openDisconnectConfirmationDialog(item)"
                        :icon="['fas', 'unlink']"
                        class="action-icon"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>Disconnect</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-overlay :value="isLocationPendingModalVisible">
      <v-card class="card-rounded not-a-link inventory-change-dialog pa-8" light width="459px">
        <h3 class="h3 mb-5">
          <v-icon class="mr-3" size="24px" color="#D91E18">error</v-icon>
          Store connection is pending.
        </h3>

        <p>There is no location assigned to <i>{{ selectedSourceStoreDomain }}</i>. To continue syncing products, please assign a location to this Source Store.</p>

        <div class="d-flex justify-end align-center mt-7">
          <v-btn @click="isLocationPendingModalVisible = false" class="btn-primary ml-6" elevation="0">Assign Location</v-btn>
        </div>
      </v-card>
    </v-overlay>

    <v-overlay :value="isDisableMultiLocationModalVisible">
      <v-card class="card-rounded not-a-link inventory-change-dialog pa-8" light width="459px">
        <h3 class="h3 mb-5">
          <v-icon class="mr-3" size="24px" color="#D91E18">warning</v-icon>
          Disable Multilocation
        </h3>

        <p>Are you sure you want to disable multilocation for <br /> <strong><i>{{ currentShop && currentShop.store_domain }}</i></strong></p>
        <p>All settings will be removed.</p>

        <div class="d-flex align-center justify-end mt-8">
          <span @click="undoDisableMultiLoaction" class="btn-dismiss pointer">Cancel</span>
          <v-btn @click="updateMultiLocationHandler" class="btn-primary ml-6" elevation="0">
            Disable
          </v-btn>
        </div>
      </v-card>
    </v-overlay>

  </v-container>
</template>
<script>

import { mapState } from "vuex";
import { LOAD_CONNECTED_SHOPS, LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CommissionModal from "@/views/shop/components/CommissionModal.vue";
import connectNewShopWidget from "@/views/shop/components/ConnectNewShopWidget";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";
import DestinationInventoryAssignment from "@/views/shop/components/DestinationInventoryAssignment.vue";
import DisconnectConfirmation from "@/components/ConfirmationBox/DisconnectConfirmation.vue";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import SourceInventoryAssignment from "@/views/shop/components/SourceInventoryAssignment";

import { createHelpers } from 'vuex-map-fields';
import { IconNewTab } from '@/icons';

const { mapFields } = createHelpers({
  getterType: 'shop_2_0/getField',
  mutationType: 'shop_2_0/updateField',
});

export default {
  name: "ShopManagementPage",

  components: {
    CommissionModal,
    connectNewShopWidget,
    DataPreparationOverlay,
    DestinationInventoryAssignment,
    DisconnectConfirmation,
    FontAwesomeIcon,
    IconNewTab,
    PageHeader,
    SourceInventoryAssignment,
  },

  data: function() {
    return {
      destinationHeadersWithML: [
        { text: "Platform", value: "platform", sortable: false, width: '7.5%' },
        { text: "Status", value: "status", sortable: false, width: '12.5%', align: 'center' },
        { text: "Store URL", value: "label", sortable: false, width: '35%' },
        { text: "Assigned Location", value: "location", sortable: false, width: '27.5%' },
        { text: "Actions", value: "actions", sortable: false, width: '12.5%', align: 'right' },
      ],
      destinationHeadersWithoutML: [
        { text: "Platform", value: "platform", sortable: false, width: '7.5%' },
        { text: "Status", value: "status", sortable: false, width: '12.5%', align: 'center' },
        { text: "Store URL", value: "label", sortable: false, width: '55%' },
        { text: "Actions", value: "actions", sortable: false, width: '12.5%', align: 'right' },
      ],
      sourceHeaders: [
        { text: "Platform", value: "platform", sortable: false, width: '7.5%' },
        { text: "Store URL", value: "label", sortable: false, width: '50%' },
        { text: "Inventory Location", value: "location", sortable: false, width: '30%' },
        { text: "Actions", value: "actions", sortable: false, width: '12.5%', align: 'right' },
      ],
      searchStr: null,
      sortBy: null,
      pageNumber: 1,
      displayedShops: null,
      showDisconnectConfirmation: false,
      targetConnectionId: null,
      sortValue: null,
      showSortByOptions: false,
      isDeleteDialogVisible: false,
      isKeepDialogVisible: false,
      selectedStoreDomain: '',
      isLocationPendingModalVisible: false,
      selectedSourceStoreDomain: '',
      isDisableMultiLocationModalVisible: false,
      isMultiLocationLoading: false,
    };
  },

  computed: {
    ...mapState('plan', ['currentActivePlan']),
    ...mapState("shop", [
      "connectedShops",
      "isLoading",
      "currentShop",
      "isLoadingConnectedShops",
    ]),
    ...mapFields([
      'isMultiLocationEnabled',
      'isCommissionModalVisible',
      'selectedStore'
    ]),
    partnerStoreLabel() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return "Source store";
      } else {
        return "Destination store";
      }
    },
    deleteMessage() {
      return `You are about to disconnect with <br><span>${this.selectedStoreDomain}</span>. <br><br>Any products currently in sync with this store will be unsynced, and will be <span style="color: #0E3B4D; font-style: normal;">DELETED</span>. <br><br>This action cannot be undone.`
    },
    deleteAndKeepMessage() {
      return `You are about to disconnect with <br><span>${this.selectedStoreDomain}</span>. <br><br>Any products currently in sync with this store will be unsynced, but will not be deleted. <br><br>This action cannot be undone.`
    },
    destinationTableHeader() {
      if (this.currentShop !== null && this.currentShop.type !== 'source') {
        return this.destinationHeadersWithML;
      } else {
        return this.destinationHeadersWithoutML;
      }
    },
    isPayoutsAvailable() {
      if(this.currentActivePlan) return this.currentActivePlan.payout_beta_on
      return false
    }
  },

  mounted: async function() {
    await this.$store.dispatch(`shop/init`).then(async () => {
      if (this.currentShop != null) {
        await this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        this.isMultiLocationEnabled = Boolean(+this.currentShop.is_multi_locations);
      }
    });

    if(this.isMultiLocationEnabled) {
      this.fetchDestinationStoreLocations();
    }
  },

  methods: {
    getStatus(item) {
      let itemStatus = "";
      const { destination_default_inventory_location, source_default_inventory_location, status } = item;

      if((status === 'active' || status === 'Active')) {
        itemStatus = 'active';
      } else if(status === 'pending' && destination_default_inventory_location !== null && source_default_inventory_location === null && this.isMultiLocationEnabled) {
        itemStatus = 'pending-with-no-source';
      } else if(status === 'pending')  {
        itemStatus = 'pending';
      }

      return itemStatus;
    },

    searchShops() {
      let sortByParam = null;
      switch(this.sortValue) {
        case "atoZ":
          sortByParam = {
            sort_by: 'store_domain',
            sort_by_desc: false,
          };
        break
        case "ztoA":
          sortByParam = {
            sort_by: 'store_domain',
            sort_by_desc: true,
          };
        break
        default:
      }

      if ((this.searchStr && this.searchStr.length > 3) || this.sortValue) {
        this.$store.dispatch(`shop/${LOAD_CONNECTED_SHOPS}`, {
          searchStr: this.searchStr || '',
          sortByParam
        }).then(() => {
          this.showSortByOptions = false;
        });
      }
    },

    openDisconnectConfirmationDialog({ connection_id, store_domain }) {
      this.targetConnectionId = connection_id;
      this.selectedStoreDomain = store_domain;
      this.showDisconnectConfirmation = true;
    },

    async disconnectShop(keepProduct) {
      if (this.targetConnectionId != null) {
        await this.$store.dispatch(`shop/disconnectShop`, {
          connectionId: this.targetConnectionId,
          keepProduct
        });
      } else {
        alert("Error: Need to specific which store to disconnect with.");
      }
      this.showDisconnectConfirmation = false;
      this.isDeleteDialogVisible = false;
      this.isKeepDialogVisible = false;
    },

    openProductPageWithShop(item) {
      this.selectedSourceStoreDomain = item.store_domain;
      if(item.status === 'pending' && this.isMultiLocationEnabled) {
        this.isLocationPendingModalVisible = true;
        return;
      }
      this.$router.replace({
        name: "ProductManagementPage",
        params: {
          productPageEntryShopId: item.id,
        },
      });
    },

    showDeleteConfirmationDialog() {
      this.isDeleteDialogVisible = true;
    },

    showKeepConfirmationDialog() {
      this.isKeepDialogVisible = true;
    },

    onCloseOuterDialogHandler(payload) {
      this.showDisconnectConfirmation = payload;
    },

    onCloseInnerDialogHandler(payload) {
      this.isDeleteDialogVisible = payload;
    },

    onCloseInnerDialogKeepHandler(payload) {
      this.isKeepDialogVisible = payload;
    },

    async updateMultiLocationHandler() {
      this.isMultiLocationLoading = true;
      await this.$store.dispatch('shop_2_0/updateMultiLocationInventory', {
        store_id: this.currentShop.id,
        value: this.isMultiLocationEnabled
      });

      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });

      await this.$store.dispatch(`shop/loadCurrentShop`);

      if(this.isMultiLocationEnabled) {
        await this.fetchDestinationStoreLocations();
      }

      this.isDisableMultiLocationModalVisible = false;
      this.isMultiLocationLoading = false;
    },

    updateMultiLocationModalHandler() {
      this.isMultiLocationLoading = true;
      this.isDisableMultiLocationModalVisible = true;
    },

    undoDisableMultiLoaction() {
      this.isMultiLocationEnabled = !this.isMultiLocationEnabled;
      this.isDisableMultiLocationModalVisible = false;
      this.isMultiLocationLoading = false;
    },

    fetchDestinationStoreLocations() {
      this.$store.dispatch('shop_2_0/fetchDestinationStoreLocations', {
        current_store_id: this.currentShop.id
      });
    },

    commissionModalHandler(store) {
      this.isCommissionModalVisible = true;
      this.selectedStore = store;
    }
  },
};
</script>

<style lang="scss">
.shop-management-page {
  .card-rounded {
    border-radius: 10px !important;
    border: 0;

    &.not-from-top {
      box-shadow: 0px 6px 6px #00000029 !important;
    }
  }

  .v-data-table {
    border: 0;
    box-shadow: none !important;
    border-radius: 0;
  }
}
.shop-management-page {
  .toggle-text-wrapper {
    background-color: #fff;
    border-radius: 4px;
    height: 55px;
    justify-content: space-between;
    margin-right: 24px;
    overflow: hidden;
    padding: 5px 15px;
    width: 100%;

    .toggle-text {
        line-height: 29px;
    }
  }
  .sortby-options {
    position: absolute;
    z-index: 2;
    border-radius: 3px;
  }
  .sort-by-btn {
    background: #fff;
  }
  .action-icon {
    cursor: pointer;
    font-size: 18px;
    color: #0e3b4d;
  }
  .v-input {
    .v-input__slot {
      background-color: #fff;
    }
  }
  .title-small-text {
    padding-left: 50px;
  }
  .search-bar {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  .store-connections-table {
    width: 100%;
    > thead {
      border-bottom: 1px solid #ccc;
    }
    .no-record-wrapper {
      text-align: center;
    }
    tr {
      td {
        font-size: 14px !important;
        .active-status-chip {
          color: #43a047;
          background: #ECFFEC;

          .status-circle {
            color: #28A228;
          }
        }

        .pending-with-no-source-icon {
          color: #AA5200;
          margin-left: 4px;
          font-size: 21px !important;
          transform: translateY(7px);
        }

        .pending-status-chip {
          color: #AA5200;
          background: #FFF2E3;

          .status-circle {
            color: #AA5200;
          }
        }
        .status-circle {
          font-size: 12px;
          margin-right: 8px;
        }
      }
    }

    th {
      background: #F6F6F7;
      border-bottom-color: #D6D6D6 !important;
      color: #0E3B4D !important;
      font-size: 14px !important;
      font-weight: bold !important;
    }
  }
}
</style>
