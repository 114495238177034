<template>
  <v-overlay :value="isCommissionModalVisible" class="dialog dialog-primary commissions-dialog">
    <v-card class="card-rounded not-a-link" v-click-outside="closeDialogHandler" width="660px">
      <v-toolbar height="71">
        <v-container class="px-0 d-flex justify-space-between">
          <v-card-title class="h3 pa-0">
            Set Commission:
          </v-card-title>
          <v-icon size="30px" color="white" class="mt-1" @click="closeDialogHandler">close</v-icon>
        </v-container>
      </v-toolbar>

      <div class="d-flex flex-column justify-space-between dialog-body pa-8">

        <v-card class="card-rounded not-a-link px-6" width="100%">

          <v-form ref="percentageCommissionForm" v-model="isPercentageValid">
          <aside class="commission-box percentage-based" :class="{ 'disabled': !isPercentageCommissionSelected }">
            <v-row>
              <v-col>
                <v-switch @change="togglePercentageCommission" v-model="isPercentageCommissionSelected" label="Percentage Commission" color="blue darken-1" dense flat inset hide-details></v-switch>
              </v-col>
            </v-row>
            <v-row class="ml-13 mt-1">
              <v-col cols="3">
                <v-text-field :rules="rules" ref="percentageInput" suffix="%" placeholder="0" solo light dense v-model="percentageRate" :disabled="!isPercentageCommissionSelected"></v-text-field>
              </v-col>
              <v-col cols="9">
                <v-tooltip right max-width="400px" color="#161616" transition="slide-x-reverse-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <p class="mb-0 commission-box-description d-flex justify-space-between py-2" v-bind="attrs" v-on="on">
                      Set commissions by percentage per product
                      <v-icon color="#161616" size="20px">mdi-help-circle-outline</v-icon>
                    </p>
                  </template>
                  <span>Example: I want to receive 20% of the value of each product I sell.</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </aside>
          </v-form>

          <v-divider class="divider"></v-divider>

          <v-form ref="fixedPriceCommissionForm" v-model="isFixedPriceValid">
            <aside class="commission-box" :class="{ 'disabled': !isFixedPriceCommissionSelected }">
              <v-row>
                <v-col>
                  <v-switch @change="toggleFixedPriceCommission" v-model="isFixedPriceCommissionSelected" label="Fixed Price Commission" color="blue darken-1" dense flat inset hide-details></v-switch>
                </v-col>
              </v-row>
              <v-row class="ml-13 mt-1">
                <v-col cols="3">
                  <v-text-field :rules="rules" ref="fixedRateInput" prefix="$" placeholder="0.00" solo light dense v-model="fixedRate" :disabled=!isFixedPriceCommissionSelected></v-text-field>
                </v-col>
                <v-col cols="9">
                  <v-tooltip right max-width="400px" color="#161616" transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <p class="mb-0 commission-box-description d-flex justify-space-between py-2" v-bind="attrs" v-on="on">
                        Set commissions using a fixed price per product
                        <v-icon color="#161616" size="20px">mdi-help-circle-outline</v-icon>
                      </p>
                    </template>
                    <span>
                      Example I want to receive $5.00 for each product I sell.
                      <br>
                      Note: Syncio will use the currency set in your store to make the calculations.
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </aside>
          </v-form>

        </v-card>

        <div class="d-flex pt-8 justify-space-between">
          <div>
            <v-btn :disabled="!isCommissionFormValid" color="primary" light height="40px" elevation="0" width="115px" :loading="loading" @click="saveCommissionHandler">Save</v-btn>
            <v-btn light outlined height="40px" elevation="0" width="115px" class="ml-4" @click="resetCommissionHandler">Reset</v-btn>
          </div>
          <v-btn light outlined height="40px" elevation="0" width="115px" @click="closeDialogHandler">Cancel</v-btn>
        </div>
      </div>

      <v-overlay :value="isUnsavedChangesModalVisible" class="dialog dialog-primary commissions-dialog">

        <v-card class="card-rounded not-a-link inner-dialog pa-8" light width="500px" v-click-outside="closeInnerDialogHandler">
          <h3 class="h3 mb-5">
            <v-icon class="mr-3" size="24px" color="#D91E18">warning</v-icon>
            Unsaved Changes
          </h3>

          <p>If you leave this, any unsaved changes will be lost.</p>

          <div class="d-flex align-center justify-end mt-8">
            <span class="btn-dismiss pointer" @click="closeInnerDialogHandler">Back</span>
            <v-btn class="btn-primary btn-leave ml-6" width="100px" elevation="0" @click="closeWithoutSaveHandler">Leave</v-btn>
          </div>
        </v-card>
      </v-overlay>

    </v-card>
  </v-overlay>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapState } from "vuex";

const { mapFields } = createHelpers({
  getterType: 'shop_2_0/getField',
  mutationType: 'shop_2_0/updateField',
});

export default {
  name: 'CommissionModal',

  data() {
    return {
      currentFixedRate: null,
      currentPercentageRate: null,
      fixedRate: null,
      isFixedPriceCommissionSelected: false,
      isPercentageCommissionSelected: true,
      loading: false,
      percentageRate: null,
      rules: [
        value => /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(value) || 'Must be a valid number greater or equal to 0.0'
      ],
      isFixedPriceValid: true,
      isPercentageValid: true,
      isUnsavedChangesModalVisible: false
    }
  },

  watch: {
    'isCommissionModalVisible'(value) {
      const { store_commission } = this.selectedStore;

      if(value) {
        if(!!store_commission && this.selectedStore.store_commission.type === 'flat_rate') {
          this.fixedRate = this.currentFixedRate = this.selectedStore.store_commission.value;
          this.isFixedPriceCommissionSelected = true;
          this.isPercentageCommissionSelected = false;
        } else if(!!store_commission && this.selectedStore.store_commission.type === 'percentage') {
          this.percentageRate = this.currentPercentageRate = this.selectedStore.store_commission.value;
          this.isPercentageCommissionSelected = true;
          this.isFixedPriceCommissionSelected = false;
        } else {
          this.fixedRate = this.currentFixedRate = null;
          this.percentageRate = this.currentPercentageRate = null;
          this.isFixedPriceCommissionSelected = false;
          this.isPercentageCommissionSelected = true;
        }
      }
    },
  },

  computed: {
    ...mapFields([
      'isCommissionFormValid',
      'isCommissionModalVisible',
      'isMultiLocationEnabled',
      'selectedStore'
    ]),

    ...mapState('shop', [
      'currentShop',
    ]),

    unsavedChanges() {
      return this.currentFixedRate !== this.fixedRate || this.currentPercentageRate !== this.percentageRate;
    },

    isCommissionFormValid() {
      return (this.isPercentageValid && this.isPercentageCommissionSelected) || (this.isFixedPriceValid && this.isFixedPriceCommissionSelected);
    }
  },

  methods: {
    closeDialogHandler() {
      if(this.unsavedChanges) {
        this.isUnsavedChangesModalVisible = true;
        return;
      }
      this.isCommissionModalVisible = false;
    },

    closeInnerDialogHandler() {
      this.isUnsavedChangesModalVisible = false;
    },

    closeWithoutSaveHandler() {
      this.closeInnerDialogHandler();
      this.resetCommissionHandler();
      this.isCommissionModalVisible = false;
    },

    togglePercentageCommission(value) {
      this.isFixedPriceCommissionSelected = !value;
    },

    toggleFixedPriceCommission(value) {
      this.isPercentageCommissionSelected = !value;
    },

    async saveCommissionHandler() {

      // Prepare Payload
      let commissionValue = "";
      let commissionType = "";

      if(this.isFixedPriceCommissionSelected) {
        commissionValue = this.fixedRate;
        commissionType = "flat_rate";
      } else if(this.isPercentageCommissionSelected) {
        commissionValue = this.percentageRate;
        commissionType = "percentage";
      }

      let payload = {
        commission_type: commissionType,
        commission_value: commissionValue,
        connection_id: this.selectedStore.connection_id,
        current_store_id: this.currentShop.id,
        target_store_id: this.selectedStore.id,
      }

      // Request
      this.loading = true;
      await this.$store.dispatch('shop_2_0/updateStoreCommission', { ...payload });
      this.loading = false;

      // Set them null for unsavedChanges
      this.fixedRate = this.currentFixedRate = null;
      this.percentageRate = this.currentPercentageRate = null;

      // Close and fetch
      this.closeDialogHandler();
      await this.$store.dispatch(`shop/init`);
      await this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
    },

    resetCommissionHandler() {
      this.fixedRate = this.currentFixedRate;
      this.percentageRate = this.currentPercentageRate;
      this.$refs.fixedPriceCommissionForm.resetValidation();
      this.$refs.percentageCommissionForm.resetValidation();
    }
  }
}
</script>
